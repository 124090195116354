import requestAnimationFrame
from 'vt-frontend-engine/app/scripts/principles/performance/requestAnimationFrame';
import _matchViewport from 'vt-frontend-engine/app/scripts/principles/responsive/match-viewport';

class StickyForm {

  constructor() {
    // Only sticky at or above this width
    this.stickyBreakpoint = 'width-xxl';
    // Store classes
    this.stoppedClass = 'contact-form-positioner--stopped';
    this.$contactFormPositioner = $('.js-contact-form-positioner');
    this.$contactFormColumn = $('.contact-form-column');
    // Placehold future measurements
    this.stoppingPoint = 0;
    this.scrollOffset = 0;
    this.contactFormHeight = 0;
    this.testPositions = undefined;
    // Bind functions
    this.init = this.init.bind(this);
    this._getPositions = this._getPositions.bind(this);
    this.updateMeasurements = this.updateMeasurements.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this._bindEvents = this._bindEvents.bind(this);
    this._setColumnHeight = this._setColumnHeight.bind(this);

    // Determines whether sticky behavior should be active or not
    this.initialized = false;
  }

  // Isolates browser measurements (needed for testing)
  _getPositions()  {
    // if testPositions was provided (only in testing), return it without measuring anything
    if(this.testPositions !== undefined){
      return this.testPositions;
    }
    const yOffset = typeof window.scrollY  == 'undefined' ? window.pageYOffset : window.scrollY;
    const positions = {
      columnOffset: this.$contactFormColumn.offset().top,
      stoppingPoint: $('#js-scrolling-form-stop').offset().top,
      contactFormHeight: $('.js-contact-form-positioner')[0].scrollHeight,
      contactOffset: this.$contactFormPositioner.offset().top - yOffset,
      scrollY: yOffset
    };
    return positions;
  }

  // Re-measure items that may have changed when components load/window is resized
  updateMeasurements() {
    let positions = this._getPositions();
    this.scrollOffset = positions.contactOffset + positions.contactFormHeight;
    this._setColumnHeight();
  }

  _bindEvents() {
    $(window).on('scroll', function () {
      requestAnimationFrame(stickyForm.handleScroll);
    });

    $(window).on('resize', function () {
      requestAnimationFrame(stickyForm.updateMeasurements);
    });
  }

  _setColumnHeight() {
    let currentColumnHeight = this.$contactFormColumn.height();
    let positions = this._getPositions();

    let columnHeight;
    if(_matchViewport(this.stickyBreakpoint)) {
      columnHeight = positions.stoppingPoint - positions.columnOffset;
    } else {
      // Screen widths smaller than xxl, set column height = to form height
      columnHeight = positions.contactFormHeight;
    }
    if(columnHeight !== currentColumnHeight){
      this.$contactFormColumn.css({'min-height': columnHeight});
    }
  }

  handleScroll() {
    if(!this.initialized){
      return;
    }

    let positions = this._getPositions();
    this._setColumnHeight();

    var totalScroll = positions.scrollY + this.scrollOffset;
    this.$contactFormPositioner.toggleClass(this.stoppedClass,
                                            totalScroll > positions.stoppingPoint);
  }

  // testPositions is an optional argument that is only supplied during tests
  // do not supply any arguments to init() during actual usage
  init(testPositions) {
    // store testPositions if given as an argument
    if(testPositions !== undefined){
      this.testPositions = testPositions;
    }
    this._bindEvents();
    this.updateMeasurements();
    this.initialized = true;
  }
}

const stickyForm = new StickyForm();
export { stickyForm, StickyForm };

// ********************************************
// USING THIS COMPONENT
// ********************************************

// 1. add these required classes around your form in your .haml file:
//    .contact-form-column
//      .contact-form-positioner.js-contact-form-positioner
//        = render 'components/contact_form', include_subjects: false, is_mini: true
//
// 2. import ONLY the lowercase 'stickyForm' in your file
//    DO NOT import the uppercase class StickyForm itself, which is only needed to account
//    for DOM timing differences in the test environment.
//    Be sure to update the relative path if needed:
//    ex: import { stickyForm } from '../../components/sticky-form/sticky-form';
//
// 3. copy the span below and paste at the place you want the form to stop:
//   (this can go inside of JSX or .haml)
//   <span id="js-scrolling-form-stop"></span>
//
// 4. If your stopping point is within a React component, call stickyForm.init
//    inside the componentDidMount function of the **outermost** React component:
//
//    componentDidMount() {
//      stickyForm.init();
//    }
//
//    Otherwise, include this in your view:
//    - content_for :page_js do
//        %script{ asset_attributes(src: 'scripts/sticky-form-import.js', defer: true) }
//
//     * be sure the stopping span is placed inside .main-content.layout-region *
//
// 5. See 'show_locale.html.haml' and 'apps/landing/startup.js' for example implementation
//
// 6. See 'tutor_galleries/index.html.haml' for non-react implementation
